// import backgroundImage from "../../data/index.jpg";

export default (theme) => ({
	welcome: {
		width: "100vw",
		height: "100vh",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
		backgroundSize: "cover",
		backgroundImage: "url(./data/index.jpg)",
		position: "relative"
	},
	title: {
		backgroundColor: "rgba(0,0,0,0.5)",
		color: "#fff",
		position: "absolute",
		top: "40px",
		left: "40px",
		padding: "10px 20px",
		textTransform: "uppercase"
	},
	poi: {
		width: 100,
		height: 100,
		borderRadius: "100%",
		backgroundColor: theme.palette.common.white,
		display: "flex",
		alignItems: "center",
		position: "absolute",
		"&.poi1": {
			left: "25%",
			top: "45%"
		},
		"&.poi2": {
			right: "35%",
			top: "45%"
		},
		"&:hover $icon": {
			fill: theme.palette.primary.dark
		}
	},
	icon: {
		width: 70,
		height: 70,
		margin: "auto",
		fill: theme.palette.primary.main
	},
	[theme.breakpoints.down("sm")]: {
		poi: {
			width: 60,
			height: 60,
			"&.poi1": {
				left: "25%",
				top: "50%"
			},
			"&.poi2": {
				right: "25%",
				top: "50%"
			}
		},
		icon: {
			width: 40,
			height: 40
		}
	},
	btnBack: {
		position: "absolute",
		top: 90,
		left: 20
	},
	toolbar: theme.mixins.toolbar
	// appBar: {
	// 	transition: theme.transitions.create([
	// 		"width",
	// 		"margin"
	// 	], {
	// 		duration: theme.transitions.duration.leavingScreen,
	// 		easing: theme.transitions.easing.sharp
	// 	}),
	// 	zIndex: theme.zIndex.drawer + 1
	// },
	// gridContainer: {
	// 	flexGrow: 1,
	// 	height: "calc(100vh - 64px)",
	// 	overflow: "auto",
	// 	position: "absolute",
	// 	top: 64
	// },
	// menuButton: {
	// 	marginLeft: 12,
	// 	marginRight: 36
	// },
	// root: {
	// 	display: "flex"
	// },
	// sidebar: {
	// 	zIndex: 1
	// },
	// tableContainer: {
	// 	height: 300
	// },
	// title: {
	// 	flexGrow: 1
	// },
	// toolbar: {
	// 	paddingLeft: 24
	// },
	// toolbarIcon: {
	// 	...theme.mixins.toolbar,
	// 	alignItems: "center",
	// 	display: "flex",
	// 	justifyContent: "flex-end",
	// 	padding: "0 8px"
	// }
});
