import styles from "./styles";
import classNames from "classnames";
import { withStyles, Button } from "@material-ui/core";
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/icons/arrowRight.svg";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { filters, canvas as canvasStore } from "../../store";

import { createjs } from "../../utils";
import {
	Popup,
	all as allHelper,
	floor as floorHelper,
	lci as lciHelper
} from "./partials";

import { ReactComponent as LightOnIcon } from "../../assets/images/icons/light-on.svg";
import { ReactComponent as LightOffIcon } from "../../assets/images/icons/light-off.svg";

class Canvas extends Component {

	constructor (props) {
		super(props);
		let { helperChooser } = props;
		if (props.poi) {
			helperChooser = "lci";
		}
		switch (helperChooser) {
		case "floor":
			this.setEvents = floorHelper.setEvents;
			this.update360 = floorHelper.update360;
			break;
		case "lci":
			this.setEvents = lciHelper.setEvents;
			this.update360 = lciHelper.update360;
			this.props.setFrame(lciHelper.findLci(this));
			break;
		default:
			this.setEvents = allHelper.setEvents;
			this.update360 = allHelper.update360;
			break;
		}
	}

	state = {
		filteredIds: [],
		pois: {},
		floors: {},
		loaded: 0,
		startX: null,
		startY: null,
		stage: null,
		canvas: null,
		popup: {
			x: 0,
			y: 0,
			open: false,
			poi: null,
			color: "inherit"
		},
		direction: 1,
		mouseTrigger: false,
		rollInterval: false,
		stateLights: true,
		currentPOI: false
	};

	componentDidMount () {
		this.setInitialState();
	}

	componentDidUpdate (prevProps) {
		const { filteredIds, stateLights } = this.state;
		const { ids, lights, floor, frame } = this.props;
		if (JSON.stringify(filteredIds) !== JSON.stringify(ids)) {
			this.updateState();
		}
		if (lights !== stateLights) {
			// eslint-disable-next-line
			this.setState({ stateLights: lights });
			this.updateState();
		}
		if (prevProps.floor !== floor) {
			this.update360(this.props.frame, this);
		}
	}

	componentWillUnmount () {
		const { shapes } = this.props;
		const { stage } = this.state;
		stage.removeAllEventListeners();
		Object.keys(shapes).forEach((name) => {
			shapes[name].removeAllEventListeners();
		});
		createjs.Ticker.removeAllEventListeners();
	}

	holdInterval = false;

	updateState = () => {
		const { ids } = this.props;
		this.setState({
			filteredIds: ids
		});
		this.update360(this.props.frame, this);
	};

	loadImages = () => {
		const { floorsImages, hasFloors } = this.props;
		if (hasFloors) {
			Object.keys(floorsImages).forEach((floorName) => {
				const floorImages = floorsImages[floorName];
				for (let index = 1; index <= floorImages.length / 2; index += 1) {
					const imageRight = floorImages[index];
					const imageLeft = floorImages[floorImages.length - index];
					imageRight.load(true);
					imageLeft.load(true);
				}
			});
		} else {
			for (let index = 1; index <= floorsImages.all.length / 2; index += 1) {
				const imageRight = floorsImages.all[index];
				const imageLeft = floorsImages.all[floorsImages.all.length - index];
				imageRight.load(true);
				imageLeft.load(true);
			}
		}
	};

	setInitialState = () => {
		const { floors, pois, lights, poi } = this.props;
		const canvas = document.getElementById("canvas");
		this.setState(
			{
				canvas,
				stage: new createjs.Stage(canvas),
				floors,
				pois,
				stateLights: lights,
				currentPOI: poi
			},
			() => {
				this.init();
				this.loadImages();
				this.update360(this.props.frame, this);
				// if (poi && typeof lciHelper.findLci === "function") {
				// 	lciHelper.findLci(this);
				// }
			}
		);
	};

	holdAnimation = (dir) => {
		if (!this.holdInterval) {
			this.holdInterval = setInterval(() => {
				this.update360(dir, this);
			}, 50);
		}
	};

	endAnimation = () => {
		if (this.holdInterval) {
			clearInterval(this.holdInterval);
			this.holdInterval = false;
		}
	};

	addNavigation = () => {
		const { stage } = this.state;
		let dragTrigger = false;

		stage.on("stagemousedown", (event) => {
			dragTrigger = true;
			this.mousePressed(event);
		});
		stage.on("stagemouseup", () => {
			dragTrigger = false;
			document.body.style.cursor = "auto";
		});
		stage.on("stagemousemove", (event) => {
			const { floor } = this.props;
			if (dragTrigger === true) {
				this.mouseMoved(event);
			}
			if (floor !== "all") {
				this.setState((prevState) => {
					prevState.popup.x = event.nativeEvent.clientX - 90;
					prevState.popup.y = event.nativeEvent.clientY + 24;
					return {
						...prevState
					};
				});
			}
		});

		document.body.style.cursor = "auto";
	};

	mousePressed = (event) => {
		this.setState({
			startX: event.rawX,
			startY: event.rawY
		});
		document.body.style.cursor = "w-resize";
	};

	mouseMoved = (event) => {
		const { startX, startY } = this.state;
		const dx = event.rawX - startX;
		const absDx = Math.abs(dx);
		const dy = event.rawY - startY;
		const absDy = Math.abs(dy);

		if (absDx > 5 && absDy < 50) {
			this.update360(-dx / absDx, this);
			this.setState({ startX: event.rawX });
		}
	};

	init = () => {
		const { canvas, stage } = this.state;

		if (!canvas || !canvas.getContext) {
			return;
		}

		stage.enableMouseOver(24);
		createjs.Touch.enable(stage);

		this.resetStage("all");
		this.addNavigation();
		this.setEvents(this);
		// TICKER
		createjs.Ticker.addEventListener("tick", () => stage.update());
		createjs.Ticker.framerate = 24;
		createjs.Ticker.useRAF = true;
	};

	resetStage = (etage) => {
		const { bg, bmp, floorsImages, frame } = this.props;
		const { stage } = this.state;
		stage.removeAllChildren();
		stage.addChild(bg);
		stage.addChild(bmp);
		bmp.image = floorsImages[etage][frame];
		stage.update();
	};

	render () {
		const { classes, poi, lights, toggleLights, hasFloors, floor } = this.props;
		const { popup } = this.state;
		return (
			<>
				<div className={classNames(classes.root, poi ? "rootLCI" : "")}>
					<canvas
						className={classes.canvas}
						id="canvas"
						width="1920"
						height="1080"
					/>
					<Popup popup={popup} />
					<div className={classes.arrowsContainer}>
						<Button
							className={classNames(classes.arrows, classes.arrowLeft)}
							variant="contained"
							color="primary"
							onMouseDown={() => {
								this.holdAnimation(-1);
							}}
							onMouseUp={this.endAnimation}
							onTouchStart={() => {
								this.holdAnimation(-1);
							}}
							onTouchEnd={this.endAnimation}
							onMouseLeave={this.endAnimation}
							onClick={() => this.update360(-1, this)}
						>
							<ArrowLeftIcon className={classNames(classes.arrowIcon)} />
						</Button>
						<Button
							className={classNames(classes.arrows, classes.arrowRight)}
							variant="contained"
							color="primary"
							onMouseDown={() => {
								this.holdAnimation(1);
							}}
							onMouseUp={this.endAnimation}
							onTouchStart={() => {
								this.holdAnimation(1);
							}}
							onTouchEnd={this.endAnimation}
							onMouseLeave={this.endAnimation}
							onClick={() => this.update360(1, this)}
						>
							<ArrowRightIcon className={classNames(classes.arrowIcon)} />
						</Button>
						{poi || (hasFloors && floor !== "all") ? null : (
							<Button
								color="primary"
								variant="contained"
								className={classNames(classes.arrows)}
								onClick={toggleLights}
							>
								{lights ? (
									<LightOnIcon
										className={classNames(
											classes.primaryColor,
											classes.arrowIcon,
											classes.eye
										)}
									/>
								) : (
									<LightOffIcon
										className={classNames(
											classes.primaryColor,
											classes.arrowIcon,
											classes.eye
										)}
									/>
								)}
							</Button>
						)}
					</div>
				</div>
			</>
		);
	}

}

const mapStateToProps = (state) => ({
	...state.filtered[state.helpers.building],
	...state.canvas[state.helpers.building],
	pois: state.canvas[state.helpers.building].all.pois,
	data: state.pois[state.helpers.building],
	lci: state.canvas[state.helpers.building].lci,
	floor: state.filters[state.helpers.building].floor,
	lights: state.canvas[state.helpers.building].lights
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
	{
		setFloor: filters.setFloor,
		setCanvasImages: canvasStore.setCanvasImages,
		setFrame: canvasStore.setFrame,
		toggleLights: canvasStore.toggleLights
	},
	dispatch
);

Canvas.propTypes = {
	bg: PropTypes.object.isRequired,
	bmp: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	// colors: PropTypes.object.isRequired,
	// data: PropTypes.object.isRequired,
	floor: PropTypes.any.isRequired,
	floors: PropTypes.object.isRequired,
	floorsImages: PropTypes.object.isRequired,
	frame: PropTypes.number.isRequired,
	hasFloors: PropTypes.bool.isRequired,
	// framesCount: PropTypes.number.isRequired,
	helperChooser: PropTypes.string.isRequired,
	// history: PropTypes.object.isRequired,
	ids: PropTypes.array.isRequired,
	// setCanvasImages: PropTypes.func.isRequired,
	// setFloor: PropTypes.func.isRequired,
	lights: PropTypes.bool.isRequired,
	poi: PropTypes.any,
	pois: PropTypes.array.isRequired,
	shapes: PropTypes.object.isRequired
};

Canvas.defaultProps = {
	poi: false
};

export default withRouter(withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(Canvas)));
