import { theme } from "../../../utils/ThemeProvider";

const setEvents = that => {
	const currentLocation = window.location.hash.replace(/#/u, "");

	const { shapes, data, colors, floors } = that.props;
	const textColor = theme.palette.primary.contrastText;
	Object.keys(shapes).forEach(name => {
		let color =
			theme.app[`backgroundColor${data[name].type.replace(/\D/u, "")}`];
		if (!color) {
			color = theme.app.default;
		}
		const shape = shapes[name];
		shape.addEventListener("click", () => {
			shape.children[1].color = textColor;
			shape.children[0].graphics._fill.style = color;
			that.props.history.push(`${currentLocation}/${shape.children[0].title}`);
		});
		shape.addEventListener("mouseover", () => {
			shape.children[1].color = color;
			shape.children[0].graphics._fill.style = textColor;
			document.body.style.cursor = "pointer";
		});
		shape.addEventListener("mouseout", () => {
			shape.children[1].color = textColor;
			shape.children[0].graphics._fill.style = color;
			document.body.style.cursor = "auto";
		});
		return shape;
	});
	Object.keys(floors).forEach(floor => {
		floors[floor].forEach(floorPois => {
			floorPois.forEach(poi => {
				poi.addEventListener("click", () => {
					that.props.history.push(`${currentLocation}/${poi.title}`);
				});
				poi.addEventListener("mouseover", () => {
					that.setState({
						popup: {
							open: true,
							color: colors[poi.title],
							poi: data[poi.title]
						}
					});
					poi.alpha = 0.75;
					document.body.style.cursor = "pointer";
				});
				poi.addEventListener("mouseout", () => {
					that.setState({
						popup: {
							open: false
						}
					});
					poi.alpha = 0.45;
					document.body.style.cursor = "auto";
				});
			});
		});
	});
};

const update360 = (dir, that) => {
	const { canvas, stage } = that.state;
	const {
		shapes,
		framesCount,
		lights,
		setFrame,
		ids,
		floors,
		floor
	} = that.props;

	let frame = Math.abs(dir) > 1 ? dir : that.props.frame + dir;

	if (frame < 0) {
		frame = framesCount - 1;
	} else if (frame > framesCount - 1) {
		frame = 0;
	}

	setFrame(frame);

	that.resetStage(floor);

	// This is need for canvas with floors
	if (floor === "all") {
		that.props.pois
			.filter(item => ids.indexOf(Object.keys(item)[0]) > -1)
			.forEach(poiItem => {
				const [name] = Object.keys(poiItem);
				const values = poiItem[name].coordinates;
				const result = values.filter(
					value => value._column === that.props.frame
				);

				shapes[name].children[0].x = -100;
				shapes[name].children[0].y = -100;
				shapes[name].children[1].x = -100;
				shapes[name].children[1].y = -100;

				if (result.length > 0 && lights) {
					const x = canvas.width * result[0]._x;
					const y = canvas.height * result[0]._y;
					shapes[name].children[0].x = x;
					shapes[name].children[0].y = y;
					shapes[name].children[1].x = x;
					shapes[name].children[1].y = y;
					stage.addChild(shapes[name]);
				}
			});
	} else {
		floors[floor].forEach(shape => {
			const poiItem = shape[that.props.frame];
			if (ids.indexOf(poiItem.title) > -1) {
				stage.addChild(poiItem);
			}
		});
	}
	stage.update();
};

export default {
	setEvents,
	update360
};
