import db from "../../data/db.json";
const buildings = Object.keys(db);

const state = buildings.reduce((initialState, building) => {
	initialState[building] = JSON.parse(JSON.stringify(db[building].filters));
	// initialState[building].floor = "all";
	return initialState;
}, {});

export default state;
