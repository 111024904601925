import { SET_FILTERED, SET_FILTERED_ON_ROUTE_CHANGE } from "./constants";
import initialState from "./initialState";

export default (state = initialState, action) => {
	switch (action.type) {
	case SET_FILTERED_ON_ROUTE_CHANGE:
		return {
			...initialState
		};
	case SET_FILTERED:
		const { filters, pois } = action;
		const dataKeys = Object.keys(pois);
		let count = 0;
		const ids = [];
		const check = (lot) => {
			const item = pois[lot];
			return (
				((typeof filters.floor === "string" &&
						(filters.floor === item.etage || filters.floor === "all")) ||
						filters.floor.indexOf("all") > -1 ||
						filters.floor.indexOf(item.floor) > -1) &&
					(filters.surface[0] <= item.surface &&
						filters.surface[1] >= item.surface) &&
					// @TODO This is need to be fixed
					// filters.price[0] &&
					(filters.price[0] <= item.price && filters.price[1] >= item.price) &&
					(filters.benefits.length === 0 ||
						filters.benefits.filter((benefit) => Boolean(item[benefit])).
							length === filters.benefits.length) &&
					filters.type.indexOf(item.type) > -1
			);
		};
		const data = {};
		dataKeys.forEach((lot) => {
			if (check(lot)) {
				ids.push(lot);
				count += 1;
				if (!data[pois[lot].type]) {
					data[pois[lot].type] = [];
				}
				data[pois[lot].type].push(pois[lot]);
			}
		});
		return {
			...state,
			[action.building]: {
				...state[action.building],
				data,
				count,
				ids
			}
		};

	default:
		return state;
	}
};
