import db from "../../data/db.json";
import { createjs, theme } from "../../utils";

const buildings = Object.keys(db);

const state = buildings.reduce((initialState, building) => {
	const colors = {};
	const dots = [];
	const floors = {};
	const lci = {};
	// db[building].hasFloors
	const helperChooser = false ? "floor" : "all";

	const lciCreator = (color) => {
		const lciContainer = new createjs.Container();
		const circle1Graphics = new createjs.Graphics();
		circle1Graphics.beginFill("white").drawCircle(0, 0, 36);
		const circle1Shape = new createjs.Shape(circle1Graphics);
		const circle2Graphics = new createjs.Graphics();
		circle2Graphics.beginFill("white").drawCircle(120, 0, 18);
		const circle2Shape = new createjs.Shape(circle2Graphics);
		const circle3Graphics = new createjs.Graphics();
		circle3Graphics.
			setStrokeStyle(14).
			beginStroke(color).
			drawCircle(190, 0, 70);
		const circle3Shape = new createjs.Shape(circle3Graphics);
		const lineGraphics = new createjs.Graphics();
		lineGraphics.beginFill("white").drawRect(0, -8, 120, 14);
		const lineShape = new createjs.Shape(lineGraphics);
		const lciText = new createjs.Text();
		lciText.set({
			text: "lci",
			font: "36px Questrial,Arial,sans,sans-serif",
			textAlign: "center",
			color,
			textBaseline: "middle",
			x: 0,
			y: 0
		});
		lciContainer.addChild(
			circle3Shape,
			circle1Shape,
			circle2Shape,
			lineShape,
			lciText
		);
		return lciContainer;
	};

	const shapes = db[building].canvas.all.pois.reduce(
		(apartmentDotsShapes, poi, index) => {
			const [name] = Object.keys(poi);
			const { data } = poi[name];
			let color = theme.app[`backgroundColor${data.type.replace(/\D/u, "")}`];
			if (!color) {
				color = theme.app.default;
			}
			lci[name] = lciCreator(color);
			colors[name] = color;
			const textColor = theme.palette.primary.contrastText;
			dots[index] = new createjs.Graphics();
			dots[index].beginFill(color);
			dots[index].drawCircle(0, 0, 22);
			// dots[index].append(text);

			const tempContainer = new createjs.Container();

			const tempObj = new createjs.Shape(dots[index]);
			tempObj.x = -100;
			tempObj.y = -100;
			tempObj.title = name;

			const text = new createjs.Text();
			text.set({
				text: tempObj.title,
				font: `${
					tempObj.title.length <= 3 ? 20 : 12
				}px Questrial,Arial,sans,sans-serif`,
				textAlign: "center",
				color: textColor,
				textBaseline: "middle",
				x: tempObj.x,
				y: tempObj.y
			});

			tempContainer.addChild(tempObj, text);

			apartmentDotsShapes[name] = tempContainer;

			return apartmentDotsShapes;
		},
		{}
	);

	const floorsImages = Object.keys(db[building].canvas).reduce(
		(floorImages, floor) => {
			if (db[building].canvas[floor].images.length > 0) {
				floorImages[floor] = db[building].canvas[floor].images.map((src, index) => {
						const image = new Image();
						if (index === 0) {
							image.src = `.${src}`;
						}
						image.load = (isLoad) => {
							if (isLoad) {
								image.src = `.${src}`;
							}
						};

						return image;
					});

				if (floor !== "all") {
					floors[floor] = db[building].canvas[floor].pois.reduce(
						(apartaments, poi) => {
							const [name] = Object.keys(poi);
							const { coordinates } = poi[name];
							const room = coordinates.reduce((apartmentPoints, coordinate) => {
								const vertices = coordinate._vertices.
									split(";").
									map((vertice) => {
										const tmp = vertice.split(",");
										return [1920 * tmp[0],
1080 * tmp[1]];
									});

								apartmentPoints[coordinate._column] = new createjs.Shape();
								apartmentPoints[coordinate._column].graphics.
									beginFill(colors[name]).
									drawPolygon(0, 0, vertices);
								apartmentPoints[coordinate._column].title = name;
								apartmentPoints[coordinate._column].alpha = 0.45;

								return apartmentPoints;
							}, []);
							apartaments.push(room);
							return apartaments;
						},
						[]
					);
				}
			}

			if (floor !== "all" && db[building].canvas[floor].images.length < 1) {
				floors[floor] = shapes;
			}
			// This is need for canvas without floor images
			return floorImages;
		},
		{}
	);

	initialState[building] = {
		...db[building].canvas,
		floorsImages,
		shapes,
		dots,
		colors,
		floors,
		bg: new createjs.Shape(),
		bmp: new createjs.Bitmap(),
		framesCount: db[building].canvas.all.images.length,
		lights: true,
		frame: 0,
		lci,
		helperChooser,
		hasFloors: false // Boolean(db[building].hasFloors)
	};

	return initialState;
}, {});

export default state;
